<template>
  <v-app>
    <v-main>
      <Header v-if="$route.name!= 'login'"/>
      <!-- <Headerxs/> -->
     
     <router-view>
     </router-view>
    
      <Footer v-if="$route.name!= 'login'"/>
    </v-main>
  </v-app>
</template>
<script>
// import HelloWorld from './components/HelloWorld';
import "./assets/style/fonts.css";
import "./assets/style/style.css";
import Header from './common/appHeader'
// import Headerxs from './common/appHeaderxs'

import Footer from './common/footer'
import axios from 'axios'
import store from './store'


export default {
  name: 'App',

  components: {
    // HelloWorld,
    Header,
    Footer,
    // Headerxs

  },
  
  beforeMount() {
    if (typeof localStorage.getItem("token") == "string") {

      // this.$store.commit("appLoading", true);
      axios({
        method: "get",
        url: "/user/admin/get",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            store.commit("appLoading", false);
            store.commit("userData", response.data.data);
            store.commit("userType", response.data.data.role);
          
          }
          else
          {
            // localStorage.removeItem("token")
            this.appLogout()

          }
        })
        .catch((err) => {
          // this.$store.commit("appLoading", false);
          console.log(err);
        });
    }
  },
  data: () => ({
    //
  }),
  methods:
  {
    appLogout() {
   store.state.userType = null;
          store.state.isLoggedIn = false;
          store.state.userData = {};
          store.state.initial = "";
          localStorage.removeItem("userType");
          localStorage.removeItem("token");
          this.$router.push("/");
          // this.$router.push("/");

      axios({
        method: "get",
        url: "/user/admin/logout",
        headers: {
          token: localStorage.getItem("token"),
        },
      }).then((response) => {
        if (response.data.status) {
          store.state.userType = null;
          store.state.isLoggedIn = false;
          store.state.userData = {};
          store.state.initial = "";
          localStorage.removeItem("userType");
          localStorage.removeItem("token");
          this.$router.push("/");
        }
      });
      
    },
  }

};
</script>

<template>
  <div>
    <v-layout wrap justify-end>
      <v-flex xs12>
        <v-menu offset-y left>
          <template v-slot:activator="{ attrs, on }">
            <v-layout wrap justify-center v-bind="attrs" v-on="on">
              <v-flex xs12 text-center align-self-center>
                <v-icon color="white" size="25"> mdi-account </v-icon>
              </v-flex>
              <v-flex class="hidden-xs-only"
                xs12
                text-center 
                align-self-center
                style="line-height: 0.5"
                pt-1
              >
                <span class="bold" style="color:white"> Account </span>
              </v-flex>
            </v-layout>
          </template>
          <v-card tile flat :elevation="0" max-width="100%">
            <v-layout wrap justify-center py-4>
              <v-flex pt-4 xs12 text-center>
                <span
                  style="
                    font-family: pbold;
                    font-size: 16px;
                    color: #000000;
                  "
                >
                  Welcome
                </span>
              </v-flex>
              <v-flex pt-2 xs12 text-center>
                <v-icon color="#CCB4B4" size="30">
                  mdi-account-circle-outline
                </v-icon>
              </v-flex>
              <v-flex xs12 text-center>
                <span
                  style="
                    font-family: pregular;
                    font-size: 13px;
                    color: #1e1b1b;
                  "
                >
                  <span> {{ appUser.phone }} </span>
                </span>
              </v-flex>
              <v-flex xs12 pa-4>
                <v-btn block dark dense color="error" @click="appLogout">
                  log out
                </v-btn>
              </v-flex>
            </v-layout>
          </v-card>
        </v-menu>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import store from "./../store";
import axios from "axios";
export default {
  data() {
    return {
      user: {},
    };
  },
  computed: {
    appLogin() {
      return store.state.email;
    },
    appUser() {
      return store.state.userData;
    },
  },
  methods: {
    
    // appLogout() {
    //   this.$store.commit("logoutUser", true);
    // },

    appLogout() {
   store.state.userType = null;
          store.state.isLoggedIn = false;
          store.state.userData = {};
          store.state.initial = "";
          localStorage.removeItem("userType");
          localStorage.removeItem("token");
          this.$router.push("/");
          // this.$router.push("/");

      axios({
        method: "get",
        url: "/user/admin/logout",
        headers: {
          token: localStorage.getItem("token"),
        },
      }).then((response) => {
        if (response.data.status) {
          store.state.userType = null;
          store.state.isLoggedIn = false;
          store.state.userData = {};
          store.state.initial = "";
          localStorage.removeItem("userType");
          localStorage.removeItem("token");
          this.$router.push("/");
        }
      });
      
    },
  },
};
</script>
import Vue from "vue";
import Router from "vue-router";
import store from "./store";

Vue.use(Router);
let router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      name:"login",
      props: true,
      component: () => import("./components/Login/login"),
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: "/admin",
      name:"admin",
      props: true,
      component: () => import("./components/adminDash2/dashBoard"),
      meta: {
        requiresAuth: true,
      },

      children: [
        {
          path: "/userlist",
          name: "userlist",
          meta: {
            requiresAuth: true,
          },
          component: () => import("./components/userList/list"),
        },
        {
          path: "/",
          name: "board",
          meta: {
            requiresAuth: true,
          },
          component: () => import("./components/board/board"),
        },
        {
          path: "/userview",
          name: "userview",
          meta: {
            requiresAuth: true,
          },
          component: () => import("./components/userList/userView"),
        },
        {
          path: "/uploaddetail",
          name: "uploaddetail",
          meta: {
            requiresAuth: true,
          },
          component: () => import("./components/userList/userUploadDetails"),
        },
        {
          path: "/privacy",
          name: "privayPolicy",
          meta: {
            requiresAuth: true,
          },
          component: () => import("./components/privacyPolicy/policy"),
        },
        {
          path: "/reports",
          name: "reports",
          meta: {
            requiresAuth: true,
          },
          component: () => import("./components/Reports/reports"),
        },
        
      ],

    },
    {
      path: "/privacyPolicy",
      props: true,
      component: () => import("./components/privacyPolicy/p"),
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: "*",
      props: true,
      component: () => import("./common/404"),
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: "/servererror",
      name: "servererror",
      props: true,
      component: () => import("./common/500"),
      meta: {
        requiresAuth: false,
      },
    },
   
  
  ],
  scrollBehavior() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0;
  },
});
router.beforeEach((to, from, next) => {
  //new lines
  localStorage.setItem("PRE", from.path);

 // end  of newely added lines
 if (
   to.matched.some((route) => route.meta.requiresAuth == true) && store.state.isLoggedIn == false
 ) {
   console.log(from);
   next({ name: "login", params: { lastPage: from } });
   return;
 }
 if (
   (to.name == "login" || to.name == "home") && store.state.isLoggedIn == true) {
   next({ name: "board" });
   return;
 }
 next();
});


export default router;
